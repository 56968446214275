export function getTeleconsultationId() {
  return window.localStorage.getItem(process.env.REACT_APP_TELECONSULTATION_ID)
}

export function setTeleconsultationId(id) {
  return window.localStorage.setItem(process.env.REACT_APP_TELECONSULTATION_ID, id)
}

export function removeTeleconsultationId() {
  return window.localStorage.removeItem(process.env.REACT_APP_TELECONSULTATION_ID)
}
