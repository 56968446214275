import _defineProperty from "/home/gitlab-runner/builds/ssj5gYSn/1/sponte-quad-produtos-novos/frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/home/gitlab-runner/builds/ssj5gYSn/1/sponte-quad-produtos-novos/frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "/home/gitlab-runner/builds/ssj5gYSn/1/sponte-quad-produtos-novos/frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { isObject } from './is.js';
export var validateAndMerge = function validateAndMerge() {
  for (var _len = arguments.length, sources = new Array(_len), _key = 0; _key < _len; _key++) {
    sources[_key] = arguments[_key];
  }

  for (var _i = 0, _sources = sources; _i < _sources.length; _i++) {
    var source = _sources[_i];

    if ((!isObject(source) || Array.isArray(source)) && typeof source !== 'undefined') {
      throw new TypeError('The `options` argument must be an object');
    }
  }

  return deepMerge.apply(void 0, [{}].concat(sources));
};
export var mergeHeaders = function mergeHeaders() {
  var source1 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var source2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var result = new globalThis.Headers(source1);
  var isHeadersInstance = source2 instanceof globalThis.Headers;
  var source = new globalThis.Headers(source2);
  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = source.entries()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var _ref3 = _step.value;

      var _ref2 = _slicedToArray(_ref3, 2);

      var key = _ref2[0];
      var value = _ref2[1];

      if (isHeadersInstance && value === 'undefined' || value === undefined) {
        result.delete(key);
      } else {
        result.set(key, value);
      }
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return != null) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }

  return result;
}; // TODO: Make this strongly-typed (no `any`).

export var deepMerge = function deepMerge() {
  var returnValue = {};
  var headers = {};

  for (var _len2 = arguments.length, sources = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    sources[_key2] = arguments[_key2];
  }

  for (var _i2 = 0, _sources2 = sources; _i2 < _sources2.length; _i2++) {
    var source = _sources2[_i2];

    if (Array.isArray(source)) {
      if (!Array.isArray(returnValue)) {
        returnValue = [];
      }

      returnValue = [].concat(_toConsumableArray(returnValue), _toConsumableArray(source));
    } else if (isObject(source)) {
      for (var _i3 = 0, _Object$entries = Object.entries(source); _i3 < _Object$entries.length; _i3++) {
        var _ref6 = _Object$entries[_i3];

        var _ref5 = _slicedToArray(_ref6, 2);

        var key = _ref5[0];
        var value = _ref5[1];

        if (isObject(value) && key in returnValue) {
          value = deepMerge(returnValue[key], value);
        }

        returnValue = _objectSpread({}, returnValue, _defineProperty({}, key, value));
      }

      if (isObject(source.headers)) {
        headers = mergeHeaders(headers, source.headers);
        returnValue.headers = headers;
      }
    }
  }

  return returnValue;
};