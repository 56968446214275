import _defineProperty from "/home/gitlab-runner/builds/ssj5gYSn/1/sponte-quad-produtos-novos/frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { requestMethods } from '../core/constants.js';
export var normalizeRequestMethod = function normalizeRequestMethod(input) {
  return requestMethods.includes(input) ? input.toUpperCase() : input;
};
var retryMethods = ['get', 'put', 'head', 'delete', 'options', 'trace'];
var retryStatusCodes = [408, 413, 429, 500, 502, 503, 504];
var retryAfterStatusCodes = [413, 429, 503];
var defaultRetryOptions = {
  limit: 2,
  methods: retryMethods,
  statusCodes: retryStatusCodes,
  afterStatusCodes: retryAfterStatusCodes,
  maxRetryAfter: Number.POSITIVE_INFINITY
};
export var normalizeRetryOptions = function normalizeRetryOptions() {
  var retry = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  if (typeof retry === 'number') {
    return _objectSpread({}, defaultRetryOptions, {
      limit: retry
    });
  }

  if (retry.methods && !Array.isArray(retry.methods)) {
    throw new Error('retry.methods must be an array');
  }

  if (retry.statusCodes && !Array.isArray(retry.statusCodes)) {
    throw new Error('retry.statusCodes must be an array');
  }

  return _objectSpread({}, defaultRetryOptions, {}, retry, {
    afterStatusCodes: retryAfterStatusCodes
  });
};