import React, { Suspense, useLayoutEffect } from 'react'

import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'react-snackbar-alert'

import '@sponte/lib-utils/dist/yup-validations'

import createTheme from '@sponte/lib-themes/dist/medplus'
import { SptThemeProvider } from '@sponte/lib-themes/dist/ThemeProvider'

import { SptGlobalCss } from '@sponte/lib-components/dist/elements/GlobalCss'

import { SptLoading } from '@sponte/lib-components/dist/atoms/Loading'
import { SptSnackbar } from '@sponte/lib-components/dist/atoms/Snackbar'

import { API_URL, INTEGRACAO_API_URL, RETAGUARDA_API_URL, RTC_API_URL, FEATURE_TOGGLES } from 'config'

import { extendApi as extendApiIntegracaoSaude } from 'api/integracao-saude'
import { extendApi as extendApiRetaguarda } from 'api/retaguarda'
import { extendApi as extendApiRtcSaude } from 'api/rtc-saude'
import { extendApi as extendApiSaude } from 'api/saude'

import i18n from 'i18n'

import * as authService from 'services/auth'
import * as teleconsultationService from 'services/teleconsultation'
import * as tenantsService from 'services/tenants'

import { AuthProvider } from 'providers/auth'
import { FeatureTogglesProvider } from 'providers/featureToggles'
import { PreferencesProvider } from 'providers/preferences'
import { TenantProvider } from 'providers/tenant'

// eslint-disable-next-line
import { ReactQueryProvider } from 'providers/reactQuery'

import DevTools from '../dev-tools'

const addToken = (request) => {
  const token = authService.getToken()

  if (token) {
    request.headers.set('authorization', `Bearer ${token}`)
  }
}

const addTenant = (request) => {
  const tenantId = tenantsService.getId()

  if (tenantId) {
    request.headers.set('x-tenant-id', tenantId)
  }
}

const refreshToken = async ({ request }) => {
  try {
    await authService.refreshToken()

    addToken(request)
  } catch (e) {
    authService.logout()

    window.location.reload()
  }
}

extendApiSaude({
  prefixUrl: API_URL,
  hooks: {
    afterResponse: [
      (request, _, response) => {
        if (response.status === 401) {
          refreshToken({ request })
        }
      }
    ],
    beforeRequest: [addTenant, addToken]
  }
})

extendApiIntegracaoSaude({
  prefixUrl: INTEGRACAO_API_URL,
  hooks: {
    afterResponse: [
      (request, _, response) => {
        if (response.status === 401) {
          refreshToken({ request })
        }
      }
    ],
    beforeRequest: [addTenant, addToken]
  }
})

extendApiRetaguarda({
  prefixUrl: RETAGUARDA_API_URL,
  hooks: {
    afterResponse: [
      (request, _, response) => {
        if (response.status === 401) {
          refreshToken({ request })
        }
      }
    ]
  }
})

extendApiRtcSaude({
  prefixUrl: RTC_API_URL,
  hooks: {
    afterResponse: [
      (request, _, response) => {
        if (response.status === 401) {
          refreshToken({ request })
        }
      }
    ],
    beforeRequest: [addTenant, addToken]
  }
})

// configura o tema padrão do projeto
const theme = createTheme({
  logo: `/assets/logo.svg`,
  illustration: `/assets/illustration.svg`,
  notFound: `/assets/notFound.svg`,
  notFoundPage: `/assets/notFoundPage.svg`
})

const Providers = ({ children }) => {
  useLayoutEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const token = params.get('token')
    const teleConsultaId = params.get('id')

    if (token || teleConsultaId) {
      if (token) {
        authService.setToken(token)
      }

      if (teleConsultaId) {
        teleconsultationService.setTeleconsultationId(teleConsultaId)
      }

      window.history.replaceState({}, document.title, `${window.location.origin}${window.location.pathname}`)
    }
  }, [])

  return (
    <FeatureTogglesProvider featureToggles={FEATURE_TOGGLES}>
      <SptThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <SnackbarProvider
            timeout={3000}
            sticky={false}
            dismissable={false}
            position="bottom-left"
            component={SptSnackbar}
          >
            <SptGlobalCss />

            <Suspense fallback={<SptLoading full size="xl" position="absolute" />}>
              <ReactQueryProvider>
                <TenantProvider
                  bootstrap={tenantsService.bootstrap}
                  onAccess={tenantsService.access}
                  onRemove={tenantsService.remove}
                >
                  <AuthProvider
                    bootstrap={authService.bootstrap}
                    onLogin={authService.login}
                    onLogout={authService.logout}
                  >
                    <PreferencesProvider>
                      <BrowserRouter>
                        {children}

                        <DevTools />
                      </BrowserRouter>
                    </PreferencesProvider>
                  </AuthProvider>
                </TenantProvider>
              </ReactQueryProvider>
            </Suspense>
          </SnackbarProvider>
        </I18nextProvider>
      </SptThemeProvider>
    </FeatureTogglesProvider>
  )
}

export default Providers
