export default {
  geral: {
    app: 'MedPlus Meet',
    acessarTeleconsulta: 'Acessar teleconsulta',
    termosAceite: 'Termos de aceite',
    dr_dra: 'Dr(a)',
    token: 'Token',
    teleconsultaId: 'ID da teleconsulta',
    iniciarTeleconsulta: 'Iniciar teleconsulta',
    teleconsultaEncerrada: 'Teleconsulta encerrada',

    feedbacks: {
      dadosDeAcessoTeleconsultaAbaixo: 'Segue abaixo os dados da sua consulta e como acessar a mesma.',
      acessarLocalBoaInternetAudioVideos: 'Acessar de um local com boa internet, áudio e vídeos disponíveis.',
      gravacaoTeleconsultaNaoPermitida: 'Não é permitida a gravação da Teleconsulta'
    },

    navegacao: {}
  },

  dashboard: {}
}
