import { RETAGUARDA_API_URL } from 'config'

import { queryTenantsControllerObterPorSubdominio } from 'api/retaguarda'

export function getId() {
  return window.sessionStorage.getItem(process.env.REACT_APP_TENANT_ID_KEY)
}

export function setId(tenantId) {
  return window.sessionStorage.setItem(process.env.REACT_APP_TENANT_ID_KEY, tenantId)
}

export function removeId() {
  return window.sessionStorage.removeItem(process.env.REACT_APP_TENANT_ID_KEY)
}

export function getSubdominioLocal() {
  return window.localStorage.getItem(process.env.REACT_APP_TENANT_SUBDOMINIO_KEY)
}

export function getSubdominioWeb() {
  return process.env.NODE_ENV === 'production' ? window.location.host.split('.')[0] : null
}

export function getSubdominio() {
  return process.env.NODE_ENV === 'production' ? getSubdominioWeb() : getSubdominioLocal()
}

export function setSubdominio(subdominio) {
  return window.localStorage.setItem(process.env.REACT_APP_TENANT_SUBDOMINIO_KEY, subdominio)
}

export function removeSubdominio() {
  return window.localStorage.removeItem(process.env.REACT_APP_TENANT_SUBDOMINIO_KEY)
}

export async function access({ subdominio }) {
  return queryTenantsControllerObterPorSubdominio({
    subdominio,
    base: RETAGUARDA_API_URL
  }).then((data) => {
    setId(data.id)
    setSubdominio(subdominio)

    let theme

    // TODO: remover theme customizado
    if (subdominio === 'retinapro') {
      theme = {
        name: 'RetinaPro',
        assets: {
          logo: `https://i.postimg.cc/xT6dYjQc/retinapro2.png`,
          loginBackgroundImage: `https://retinapro.com.br/wp-content/uploads/2018/08/retinapro-20.jpg`
        },
        colors: {
          primary: '#03A8A8',
          secondary: '#7159a2'
        }
      }
    }

    if (subdominio === 'hospitaldeolhos') {
      theme = {
        name: 'Hospital de Olhos do Sudoeste do Paraná',
        assets: {
          logo: `http://hospitaldeolhosdosudoeste.com.br/wp-content/uploads/2015/08/logo.png`,
          loginBackgroundImage: `http://hospitaldeolhosdosudoeste.com.br/wp-content/uploads/2015/08/oftalmologia-estrutura-1-41.jpg`
        },
        colors: {
          primary: '#0C4DA2',
          secondary: '#7159a2'
        }
      }
    }

    return { ...data, theme }
  })
}

export function remove() {
  removeId()
  removeSubdominio()

  return Promise.resolve(null)
}

export function bootstrap() {
  const subdominio = getSubdominio()

  if (!subdominio) {
    removeId()
    return Promise.resolve(null)
  }

  return access({ subdominio }).catch(remove)
}
