export default {
  geral: {
    app: 'MedPlus Meet EN',
    acessarTeleconsulta: 'Access teleconsultation',
    termosAceite: 'Terms of acceptance',
    dr_dra: 'Dr(a)',
    token: 'Token',
    teleconsultaId: 'Teleconsultation ID',
    iniciarTeleconsulta: 'Start teleconsultation',
    teleconsultaEncerrada: 'TeleconsultationClosed',

    feedbacks: {
      dadosDeAcessoTeleconsultaAbaixo: 'Below are the details of your query and how to access it.',
      acessarLocalBoaInternetAudioVideos: 'Access from a place with good internet, audio and videos available.',
      gravacaoTeleconsultaNaoPermitida: 'It is not allowed to record the Teleconsultation'
    },

    navegacao: {}
  },

  dashboard: {}
}
