import jwtDecode from 'jwt-decode'

import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { queryTeleConsultasControllerBuscarCliente } from 'api/rtc-saude'
import { mutationUsuariosControllerLogar } from 'api/saude'

import { getTeleconsultationId, setTeleconsultationId, removeTeleconsultationId } from './teleconsultation'

export function getToken() {
  return window.localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN_KEY)
}

export function setToken(token) {
  return window.localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN_KEY, token)
}

export function removeToken() {
  return window.localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN_KEY)
}

export function getRefreshToken() {
  return window.localStorage.getItem(process.env.REACT_APP_AUTH_REFRESH_TOKEN_KEY)
}

export function setRefreshToken(token) {
  return window.localStorage.setItem(process.env.REACT_APP_AUTH_REFRESH_TOKEN_KEY, token)
}

export function removeRefreshToken() {
  return window.localStorage.removeItem(process.env.REACT_APP_AUTH_REFRESH_TOKEN_KEY)
}

export function bootstrap() {
  const id = getTeleconsultationId()
  const token = getToken()

  if (!token) {
    return Promise.resolve(null)
  }

  return queryTeleConsultasControllerBuscarCliente({ id, clienteId: getSafe(jwtDecode(token), 'nameid') }).catch(() =>
    Promise.resolve(null)
  )
}

function authenticate(params = {}) {
  const handleLogin = mutationUsuariosControllerLogar()

  return handleLogin(params).then((data) => {
    setToken(data.accessToken)
    setRefreshToken(data.refreshTokenInfo.refreshToken)

    return data
  })
}

export function refreshToken() {
  const token = getRefreshToken()

  return authenticate({ refreshToken: token, grantType: 'refresh_token' })
}

export function login({ teleconsultaId: id, token }) {
  const clienteId = () => {
    try {
      return getSafe(jwtDecode(token), 'nameid', null)
    } catch (e) {
      return null
    }
  }

  setTeleconsultationId(id)
  setToken(token)

  return queryTeleConsultasControllerBuscarCliente({ id, clienteId }).catch(() => {
    removeTeleconsultationId()
    removeToken()
    return Promise.reject()
  })
}

export function logout() {
  removeToken()
  removeRefreshToken()

  return Promise.resolve(null)
}
