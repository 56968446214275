export var supportsStreams = function () {
  var duplexAccessed = false;
  var hasContentType = false;
  var supportsReadableStream = typeof globalThis.ReadableStream === 'function';

  if (supportsReadableStream) {
    hasContentType = new globalThis.Request('https://a.com', {
      body: new globalThis.ReadableStream(),
      method: 'POST',

      // @ts-expect-error - Types are outdated.
      get duplex() {
        duplexAccessed = true;
        return 'half';
      }

    }).headers.has('Content-Type');
  }

  return duplexAccessed && !hasContentType;
}();
export var supportsAbortController = typeof globalThis.AbortController === 'function';
export var supportsFormData = typeof globalThis.FormData === 'function';
export var requestMethods = ['get', 'post', 'put', 'patch', 'head', 'delete'];

var validate = function validate() {
  return undefined;
};

validate();
export var responseTypes = {
  json: 'application/json',
  text: 'text/*',
  formData: 'multipart/form-data',
  arrayBuffer: '*/*',
  blob: '*/*'
}; // The maximum value of a 32bit int (see issue #117)

export var maxSafeTimeout = 2147483647;
export var stop = Symbol('stop');