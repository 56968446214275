import _slicedToArray from "/home/gitlab-runner/builds/ssj5gYSn/1/sponte-quad-produtos-novos/frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useReducer as useReducerBase, useCallback } from 'react';
import { useDeepCompareMemo } from './useDeepCompareMemo';
export function useReducer(reducerBase, initialState) {
  var reducer = useCallback(reducerBase, []);

  var _useReducerBase = useReducerBase(reducer, initialState),
      _useReducerBase2 = _slicedToArray(_useReducerBase, 2),
      state = _useReducerBase2[0],
      dispatch = _useReducerBase2[1];

  var customDispatch = useCallback(function (type, payload) {
    return dispatch({
      type: type,
      payload: payload
    });
  }, [dispatch]);
  var values = useDeepCompareMemo(function () {
    return [state, customDispatch, dispatch];
  }, [state, customDispatch]);
  return values;
}