export default {
  geral: {
    app: 'MedPlus Meet SP',
    acessarTeleconsulta: 'Acceder a la teleconsulta',
    termosAceite: 'Condiciones de aceptación',
    dr_dra: 'Dr',
    token: 'Símbolo',
    teleconsultaId: 'ID de teleconsulta',
    iniciarTeleconsulta: 'Iniciar teleconsulta',
    teleconsultaEncerrada: 'Teleconsulta cerrada',

    feedbacks: {
      dadosDeAcessoTeleconsultaAbaixo: 'A continuación se muestran los detalles de su consulta y cómo acceder a ella.',
      acessarLocalBoaInternetAudioVideos: 'Acceda desde un lugar con buena Internet, audio y videos disponibles.',
      gravacaoTeleconsultaNaoPermitida: 'No se permite la grabación de teleconsulta'
    },

    navegacao: {}
  },

  dashboard: {}
}
