import React, { useMemo, Suspense, lazy } from 'react'

import { SptLoading } from '@sponte/lib-components/dist/atoms/Loading'

import { getToken } from 'services/auth'
import { getTeleconsultationId } from 'services/teleconsultation'

import { useAuth } from 'providers/auth'

const Client = lazy(() => import('./pages/client'))
const Login = lazy(() => import('./pages/auth'))

const App = () => {
  const { eu } = useAuth()

  const Component = useMemo(() => {
    if (!getToken() || !getTeleconsultationId() || !eu) {
      return Login
    }

    return Client
  }, [eu])

  return (
    <Suspense fallback={<SptLoading full size="xl" position="absolute" />}>
      <Component />
    </Suspense>
  )
}

export default App
